import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO
      title="Page not found"
      description="Instead, look at our dictionary to find what you are looking for"
    />
    <h1>Oops&hellip;</h1>
    <p>We could not find the page you were looking for.</p>
    <p>
      How about checking out the <Link to="/dictionary">Dictionary</Link> instead?
    </p>
  </Layout>
)

export default NotFoundPage
